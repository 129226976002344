import React from "react"
import { navigate } from "gatsby"
import {SEO, Image, Button, Input} from 'components'
import {BannerWeb} from 'images'
import {Layout, InsurancesCarousel, OffersBanners, WellnessBanner} from 'widgets'
class IndexPage extends React.Component{
  state = {
    form: {
      offer: ''
    },
    onlineDoctor: 'https://www.comparaassist.com/servicio-telemedicina', //https://www.comparaassist.com/servicio-telemedicina
    expatriados: 'https://www.comparaassist.com/contacto' //https://www.comparaassist.com/seguro-expatriados
  }
  render(){
    return(
      <Layout>
        <SEO
          title="Comparador de Asistencia y Seguro de viaje. Telemedicina viajeros"
          description="Comparador de asistencia al viajero con las mejores opciones de seguro de viaje para extranjeros, telemedicina y consultas médicas online."
          keywords="elegir entre diferentes seguros de viaje, servicio de telemedicina, comparador de seguros de viaje baratos, comparador de seguros de salud, seguro extranjeros, comparador de seguros viajeros; mejores seguros de viaje, consulta médica online"
        />
        <div
          className="home text-center"
          id="cStep0"
        >
          {/* Home Banner */}
          <div
            className="home-banner"
            style={{
              backgroundImage: `url(${BannerWeb})`,
              backgroundSize: 'contain'
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 title">
                  <h2 className="h1 text-secondary text-left col-12 mobile-display-none">
                    <strong>¡Consulta, escoge y disfruta de tu cobertura!</strong>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row w-100 text-center float-cards">
              <div className="col-md-4 col-sm-12 p-1 quote-insurance">
                <div className="card">
                  <div className="card-body">
                    <div className="image-container">
                      <Image
                        uri="airplane.jpg"
                      />
                    </div>
                    <h5 className="card-title lead text-purple font-weight-bold">Seguro de <br/> Viaje</h5>
                  </div>
                  <div className="button-container">
                    <Button
                      label="Cotizar"
                      onClick={() => navigate('/travel-insurance')}
                      className="pink"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 p-1 quote-health">
                <a href="#">
                  <div className="card quote"
                    // onclick="formData(6, this.id); tipoSeguro('salud');"
                  >
                    <div className="card-body">
                      <div className="image-container">
                        <Image
                          uri="heart.jpg"
                        />
                      </div>
                      <h5 className="card-title lead text-purple font-weight-bold">Seguro para extranjeros</h5>
                    </div>
                    <div className="button-container">
                      <a
                        href={this.state.expatriados}
                        className="btn btn-pink"
                        target="_blank"
                      >
                        Cotizar
                      </a>
                      {/* <Button
                        label="Cotizar"
                        onClick={() => console.log('>>: clicked')}
                        className="pink"
                      /> */}
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4 col-sm-12 p-1 quote-doctor">
                <a href="#">
                  <div className="card">
                    <div className="card-body">
                      <div className="image-container doctor-img">
                        <Image
                          uri="doctor.jpg"
                        />
                      </div>
                      <h5 className="card-title lead text-purple font-weight-bold">Tu doctor <br/> en linea</h5>
                    </div>
                    <div className="button-container">
                      <a
                        // onClick={() => navigate('/servicio-telemedicina')}
                        className="btn btn-pink"
                        target="_blank"
                        href={this.state.onlineDoctor}
                      >
                        Ver más
                      </a>
                      {/* <Button
                        label="Ver más"
                        onClick={() => console.log('>>: clicked')}
                        className="pink"
                      /> */}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* End Home Banner */}
          {/* Home Services */}
          <div className="home-services">
            <div className="text-center title">
              <h3>
                Con Compara Assist: <br/>
                Compara y elige en un solo lugar
              </h3>
              <h2>
                Nuestros&nbsp;
                <span className="text-pink">servicios</span>
              </h2>
            </div>
            <div className="container">
              <div className="cards-container">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="service-card">
                      <div className="row">
                        <div className="col-2 p-0 m-0 text-right">
                          <div className="image-container">
                            <Image
                              uri='gray-airplane.jpg'
                            />
                          </div>
                        </div>
                        <div className="col-10 p-0 m-0 text-left">
                          <p className="text-purple font-weight-bold">
                            Seguro de&nbsp;
                            <span className="text-pink">
                              viaje
                            </span>
                          </p>
                        </div>
                      </div>
                      <p className="text-dark-gray text-left">
                        Cuando decides salir de tu zona de confort y explorar el mundo, es mejor acompañar esa decisión con la protección perfecta para tu viaje. Nosotros te brindamos diferentes opciones ¡Tú eliges!
                      </p>
                      <div className="button-container">
                        <Button
                          label="Ver detalles"
                          onClick={() => navigate('/travel-insurance')}
                          className="purple"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="service-card">
                      <div className="row">
                        <div className="col-2 p-0 m-0 text-right">
                          <div className="image-container">
                            <Image
                              uri='heart.jpg'
                            />
                          </div>
                        </div>
                        <div className="col-10 p-0 m-0 text-left">
                          <p className="text-purple font-weight-bold">
                            Seguro de&nbsp;
                            <span className="text-pink">
                              Extranjeros
                            </span>
                          </p>
                        </div>
                      </div>
                      <p className="text-dark-gray text-left">
                        Si tu estadía en el exterior por trabajo o estudios, mereces la atención adecuada para cualquier situación médica o de viaje que se te presente como si estuvieras en tu país de origen.
                      </p>
                      <div className="button-container">
                        <a
                          href={this.state.expatriados}
                          className="btn btn-purple"
                          target="_blank"
                        >
                          Ver detalles
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="service-card">
                      <div className="row">
                        <div className="col-2 p-0 m-0 text-right">
                          <div className="image-container">
                            <Image
                              uri='gray-doctor.jpg'
                            />
                          </div>
                        </div>
                        <div className="col-10 p-0 m-0 text-left">
                          <p className="text-purple font-weight-bold">
                            Consulta médica&nbsp;
                            <span className="text-pink">
                              online
                            </span>
                          </p>
                        </div>
                      </div>
                      <p className="text-dark-gray text-left">
                        Con nuestro servicio de telemedicina, contacta con uno de nuestros médicos desde cualquier rincón del mundo sin problemas por distancia o tiempo. Solo necesitas un celular o pc.
                      </p>
                      <div className="button-container">
                        <a
                          // onClick={() => navigate('/servicio-telemedicina')}
                          className="btn btn-purple"
                          target="_blank"
                        >
                          Ver detalles
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Home Services */}
          {/* Home Spam services */}
          <div className="spam-services">
            <div className="container">
              <div className="buy-now">
                <div className="spam">
                  <div className="row">
                    <div className="col-md-8 col-sm-6">
                      <h3 className="text-purple">¿Por que escoger Compara Assist?</h3>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="button-container">
                        <Button
                          label="Compara Ahora"
                          onClick={() => console.log('>>: clicked')}
                          className="pink"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="features text-left">
                  <p className="text-purple">
                    1. Muchos de nuestros aliados cuentan con cobertura ante Covid-19
                  </p>
                  <p className="text-purple">
                    2. Somos la forma segura y sencilla para consultar las distintas opciones para tu cuidado
                  </p>
                  <p className="text-purple">
                    3. Nuestro comparador de seguros para todo tipo de viajero te permite:
                  </p>
                </div>
              </div>
            </div>
            <div className="permissions">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="row">
                      <div className="col-4 text-right">
                        <div className="image-container">
                          <Image
                            uri='reloj.jpg'
                          />
                        </div>
                      </div>
                      <div className="col-8 text-left">
                        <h3 className="text-purple">
                          Ahorrar&nbsp;
                          <span className="text-pink">
                            Tiempo
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="row">
                      <div className="col-4 text-right">
                        <div className="image-container">
                          <Image
                            uri='check.jpg'
                          />
                        </div>
                      </div>
                      <div className="col-8 text-left">
                        <h3 className="text-purple">
                          Elegir&nbsp; <br/>
                          <span className="text-pink">
                            la mejor opción
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="row">
                      <div className="col-4 text-right">
                        <div className="image-container">
                          <Image
                            uri='search.jpg'
                          />
                        </div>
                      </div>
                      <div className="col-8 text-left">
                        <h3 className="text-purple">
                          Encontrar&nbsp; <br/>
                          <span className="text-pink">
                            descuentos increibles
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Spam services */}
          {/* Sliders */}
          <div className="home-sliders">
            <InsurancesCarousel
              
            />
          </div>
          {/* End Sliders */}
          {/*  Post Slider banners */}
          <WellnessBanner
          
          />
          {/* Banners sliders */}
          {/* Card Address */}
            <OffersBanners
            />
          {/* End Card Address */}
        </div>
      </Layout>
    )
  }
}

export default IndexPage
